import { Component, Input, OnInit } from '@angular/core';
import { ViewPropertyConfiguration } from '@contrail/client-views';
import { AggregateRowEntity } from '../pivot-aggregate-helper';

@Component({
  selector: 'app-aggregate-row',
  templateUrl: './aggregate-row.component.html',
  styleUrls: ['./aggregate-row.component.scss'],
})
export class AggregateRowComponent implements OnInit {
  @Input() properties: Array<ViewPropertyConfiguration>;
  @Input() columnGroupingKeys?: string[];
  @Input() side: string;
  @Input() aggregateRow: AggregateRowEntity;
  @Input() showGoals: boolean;

  constructor() {}

  ngOnInit(): void {}
}

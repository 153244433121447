import { Component, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges, ViewChild } from '@angular/core';
import {
  AggregateColumnEntity,
  GroupingPropertyValue,
  PivotAggregateHelper,
} from '@components/pivot-grid/pivot-aggregate-helper';
import { DEFAULT_ROW_HEIGHT_PX } from '../pivot-header-column-resize-helper';

@Component({
  selector: 'app-pivot-grouping-header-cell',
  templateUrl: './pivot-grouping-header-cell.component.html',
  styleUrls: ['./pivot-grouping-header-cell.component.scss'],
})
export class PivotGroupingHeaderCellComponent implements OnInit, OnChanges {
  @Input() value: GroupingPropertyValue;
  @Input() childColumns: AggregateColumnEntity;
  @Input() level: number = 0;
  @Input() numberOfLevelsToOffset: number = 0;
  @Input() showGoals: boolean = false;
  @ViewChild('headerCell') headerCell: ElementRef;
  // @Output() resizeColumn: EventEmitter<any> = new EventEmitter();

  public label: string;
  public columnId: string;
  private isEmptyHeader = false;

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && this.value) {
      this.isEmptyHeader = this.value.label === PivotAggregateHelper.EMPTY_VALUE;
      this.label = this.getLabelOfValue();
      this.columnId = this.buildColumnId();
    }
  }

  ngAfterViewInit(): void {
    if (this.isEmptyHeader) {
      this.addClass('italic');
      this.addClass('text-gray-400');
    }
  }

  private getLabelOfValue(): string {
    if (this.isEmptyHeader) {
      return 'Empty';
    }

    if (this.value.aggregateFunction) {
      return `${this.value.label} (${this.value.aggregateFunction})`;
    }

    return this.value.label;
  }

  private buildColumnId(): string {
    if (this.isEmptyHeader) {
      return `empty_${this.level}`;
    }

    return `${this.value?.label?.replace(/ /g, '_')}_${this.level}`;
  }

  private addClass(className: string) {
    this.renderer.addClass(this.headerCell.nativeElement, className);
  }

  public get headerCellHeight(): string {
    return `calc(100% - ${this.showGoals ? DEFAULT_ROW_HEIGHT_PX : 0}px)`;
  }
}

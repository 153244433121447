import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { PropertyValueFormatter } from '@contrail/types';
import { ViewPropertyAggregateFunction, ViewPropertyConfiguration } from '@contrail/client-views';
import {
  AGGREGATE_FUNCTION_MAP,
  AggregateRowEntity,
  AggregateValue,
} from '@components/pivot-grid/pivot-aggregate-helper';

const formatter = new PropertyValueFormatter();
@Component({
  selector: 'app-column-aggregate',
  templateUrl: './column-aggregate.component.html',
  styleUrls: ['./column-aggregate.component.scss'],
})
export class ColumnAggregateComponent implements OnInit, OnDestroy, OnChanges {
  @Input() property: ViewPropertyConfiguration;
  @Input() aggregateRow: AggregateRowEntity;
  @Input() columnKey: string;
  @Input() showGoals: boolean;
  public value: number;
  public display: string;
  public goalDisplay: string;

  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.computeValue();
  }

  computeValue() {
    if (!this.property) {
      return;
    }

    const functionType = AGGREGATE_FUNCTION_MAP[this.property.aggregateFunction];

    const aggregateValue = this.getValueInRow(this.aggregateRow);
    this.value = aggregateValue ? aggregateValue[functionType] : 0;
    this.setDisplayValue();
  }

  getValueInRow(row: AggregateRowEntity): AggregateValue {
    if (this.columnKey) {
      return row.aggregates[this.columnKey]?.[this.property.slug];
    }

    if (row.aggregates['*']) {
      return row.aggregates['*']?.[this.property.slug];
    }
  }

  setDisplayValue() {
    if (this.property.aggregateFunction === ViewPropertyAggregateFunction.COUNT) {
      const count = this.value ?? 0;
      this.display = count.toString();
      return;
    }

    this.display = formatter.formatValueForProperty(this.value, this.property.propertyDefinition);
  }
}

import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  EventEmitter,
  Output,
} from '@angular/core';
import { ViewDefinition, ViewPropertyConfiguration } from '@contrail/client-views';
import { Subscription } from 'rxjs';
import { PivotGridManager } from './pivot-grid.manager';
import { PivotContextMenuComponent } from './context-menu/pivot-context-menu.component';
import { PivotGoals } from './pivot-goals';
import { PivotGridService } from './pivot-grid-service';
import { PivotConfiguratorHelper } from './pivot-view-configurator/pivot-configurator-helper';
import { AggregateColumnEntity, AggregateRowEntity, PivotAggregateHelper } from './pivot-aggregate-helper';
import { PivotHeaderColumnResizeHelper } from './header-row/pivot-header-column-resize-helper';
import { ChangeObject } from '@common/entities/entities.interfaces';

@Component({
  selector: 'app-pivot-grid',
  templateUrl: './pivot-grid.component.html',
  styleUrls: ['./pivot-grid.component.scss'],
})
export class PivotGridComponent implements OnInit, OnDestroy, OnChanges {
  @Input() data: any[] = [];
  @Input() goals: Array<PivotGoals>;
  @Input() gridWidth = 1000; // maximum grid width: if calculated gridTotalWidth exceeds this value, horizontal scroll will appear
  @Input() gridHeight = 500;
  @Input() viewDefinition: ViewDefinition;

  @Output() viewDefinitionUpdated = new EventEmitter<ChangeObject<ViewDefinition>>();

  @ViewChild('verticalScroll') verticalScroll: ElementRef;
  @ViewChild(PivotContextMenuComponent) contextMenu: PivotContextMenuComponent;

  public leftProperties: ViewPropertyConfiguration[] = [];
  public rightProperties: ViewPropertyConfiguration[] = [];
  public leftSideColumnWidth = 0;
  public rightSideColumnWidth = 0;
  public gridTotalWidth = this.gridWidth;
  public pivotData: AggregateRowEntity[] = [];
  public rowsToRender = 0;
  public rowViewPortHeight = 0;
  public rowStartIndex = 0;
  public hoveredRowId = '';
  public rowContainerHeight = 0; // height of visible row areas without header
  public rowGroupingProperties: Array<ViewPropertyConfiguration>;
  public columnGroupingValues: AggregateColumnEntity;
  public columnGroupingKeys: string[] = [];
  public showGoals = false;
  public gridViewRowHeight = 24;
  public scrollVerticalPercentage = 0;
  public scrollableHeight = 0;
  public rightColumnMinWidth = 0;
  public subscriptions = new Subscription();
  public headerHeightPx = 0;
  public grandTotalsRowData: AggregateRowEntity;

  public readonly TOTALS_ROW_HEIGHT = 47;

  constructor(
    private pivotGridService: PivotGridService,
    private pivotGridManager: PivotGridManager,
  ) {
    this.subscriptions.add(
      this.pivotGridManager.pivotData$.subscribe((pivotData) => {
        if (pivotData) {
          this.pivotData = pivotData;
          this.calculateViewportHeight();
        }
      }),
    );

    this.subscriptions.add(
      this.pivotGridManager.pivotGrandTotalsByColumn$.subscribe((grandTotals) => {
        if (grandTotals) {
          this.grandTotalsRowData = grandTotals;
        }
      }),
    );

    this.subscriptions.add(
      this.pivotGridManager.viewDefinitionUpdate$.subscribe((viewUpdate) => {
        if (viewUpdate) {
          this.viewDefinitionUpdated.emit(viewUpdate);
        }
      }),
    );

    this.subscriptions.add(
      this.pivotGridService.showGoals$.subscribe((shouldShowGoals) => {
        if (this.showGoals !== shouldShowGoals) {
          this.viewDefinitionUpdated.emit({
            id: this.viewDefinition.id,
            changes: { controls: { ...this.viewDefinition.controls, showGoals: shouldShowGoals } },
          });
        }
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.viewDefinition) {
      this.pivotGridManager.setCurrentGridView(this.viewDefinition);
    }

    const hasGridDataOrConfigChanged = changes.viewDefinition || changes.data;
    if (hasGridDataOrConfigChanged) {
      this.configureGridForChanges(changes);
      return;
    }

    const hasGridSizeChanged = Boolean(
      (changes.gridWidth || changes.gridHeight) &&
        (changes.gridWidth?.currentValue !== changes.gridWidth?.previousValue ||
          changes.gridHeight?.currentValue !== changes.gridHeight?.previousValue),
    );

    if (hasGridSizeChanged) {
      this.configureGrid({ calculateData: false, keepExpandedRows: true });
      return;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {}

  private configureGridForChanges(changes: SimpleChanges) {
    const haveConfiguredPropertiesChanged = Boolean(
      changes.viewDefinition &&
        !this.pivotGridManager.arePropertiesEqual(
          changes.viewDefinition?.previousValue,
          changes.viewDefinition?.currentValue,
        ),
    );

    if (haveConfiguredPropertiesChanged) {
      this.configureGrid({ calculateData: true, keepExpandedRows: true });
      return;
    }

    if (changes.data) {
      this.configureGrid({ calculateData: true, keepExpandedRows: true });
      return;
    }

    const haveSortsChanged = !this.pivotGridManager.areSortsEqual(
      changes.viewDefinition.previousValue,
      changes.viewDefinition.currentValue,
    );

    if (haveSortsChanged) {
      this.pivotGridManager.sortPivotData(changes.viewDefinition.currentValue);
      this.configureColumnGroupings();
      return;
    }

    this.configureGrid({ calculateData: false, keepExpandedRows: true });
  }

  configureGrid(options: { calculateData: boolean; keepExpandedRows: boolean }) {
    const { calculateData, keepExpandedRows } = options;

    this.setViewControls();

    this.rowGroupingProperties = this.viewDefinition.properties.filter(
      (property) =>
        PivotConfiguratorHelper.isRowGroupingProperty(property) && property.enabled && property.propertyDefinition,
    );

    this.leftProperties = this.rowGroupingProperties;
    this.rightProperties = this.viewDefinition.properties.filter(
      (property) => property.enabled && !property.frozen && property.propertyDefinition,
    );

    this.configureColumnGroupings();
    this.configureGridDimensions();

    if (calculateData) {
      this.handleCalculatePivotData({ keepExpandedRows: Boolean(keepExpandedRows) });
    }

    this.rowsToRender = Math.ceil(this.gridHeight / this.gridViewRowHeight);
    setTimeout(() => {
      this.scrollableHeight =
        this.verticalScroll.nativeElement.scrollHeight - this.verticalScroll.nativeElement.clientHeight;
    }, 100);
  }

  private configureColumnGroupings() {
    const columnGroupingProperties = this.viewDefinition.properties.filter(
      (property) =>
        PivotConfiguratorHelper.isColumnGroupingProperty(property) && property.enabled && property.propertyDefinition,
    );

    this.columnGroupingValues = PivotAggregateHelper.buildAggregateColumns(
      this.data,
      columnGroupingProperties,
      this.rightProperties,
      this.viewDefinition?.sorts,
    );

    this.columnGroupingKeys = PivotAggregateHelper.buildColumnGroupingKeys(this.columnGroupingValues);
  }

  private setViewControls() {
    const showGoalsControl = Boolean(this.viewDefinition?.controls?.showGoals);
    const didShowGoalsChange = showGoalsControl !== this.showGoals;
    if (didShowGoalsChange) {
      this.showGoals = showGoalsControl;
    }
  }

  private configureGridDimensions() {
    const dimension = this.pivotGridManager.calculateWidth(this.viewDefinition, this.columnGroupingValues);
    this.leftSideColumnWidth = dimension.leftSideColumnWidth;
    this.rightSideColumnWidth = dimension.rightSideColumnWidth;

    this.headerHeightPx = this.pivotGridManager.calculateHeaderHeight(this.viewDefinition, this.showGoals);
    this.rowContainerHeight = this.gridHeight - this.headerHeightPx;

    if (this.gridWidth > this.leftSideColumnWidth + this.rightSideColumnWidth) {
      // no horizontal scroll
      this.gridTotalWidth = this.leftSideColumnWidth + this.rightSideColumnWidth + 21;
      this.rightColumnMinWidth = this.rightSideColumnWidth;
    } else {
      this.gridTotalWidth = this.gridWidth + 21;
      this.rightColumnMinWidth = 0;
    }
  }

  @HostListener('mousewheel', ['$event']) // for window scroll events
  onMouseWheel(event) {
    //event.preventDefault();
    const amount = event.deltaY;
    this.scrollVertical(-amount);
  }

  private handleCalculatePivotData(options: { keepExpandedRows?: boolean }) {
    const { keepExpandedRows } = options;

    if (this.data.length > 0) {
      this.pivotGridManager.calculatePivotData(this.data, this.viewDefinition, keepExpandedRows);
      return;
    }

    if (this.pivotGridManager.pivotData?.length > 0) {
      this.pivotGridManager.clearPivotData();
    }
  }

  private scrollVertical(delta) {
    if (this.scrollableHeight <= 0) {
      return;
    }
    let multiplier = -1;
    if (delta < 0) {
      multiplier = 1;
    }
    const oneRowPercentage = this.gridViewRowHeight / this.scrollableHeight;
    let newPercentage = this.scrollVerticalPercentage + oneRowPercentage * multiplier;
    newPercentage = newPercentage < 0 ? 0 : newPercentage;
    newPercentage = newPercentage > 1 ? 1 : newPercentage;
    const scrollTop =
      newPercentage * (this.verticalScroll.nativeElement.scrollHeight - this.verticalScroll.nativeElement.clientHeight);
    this.verticalScroll.nativeElement.scrollTop = scrollTop;
  }

  onScroll(event) {
    const target = event.target;
    if (target.scrollHeight > target.clientHeight) {
      this.scrollVerticalPercentage = target.scrollTop / (target.scrollHeight - target.clientHeight);
      const hiddenVerticalHeight = this.rowViewPortHeight - this.gridHeight;
      this.scrollableHeight = hiddenVerticalHeight;
      const hiddenRowCount = hiddenVerticalHeight / this.gridViewRowHeight;
      let index = 0;
      index = (hiddenRowCount + 2) * this.scrollVerticalPercentage; // added 2 so that the last row is not half-hidden.
      index = Math.round(index);
      this.rowStartIndex = index;
    } else {
      this.rowStartIndex = 0;
    }
  }

  calculateViewportHeight() {
    this.rowViewPortHeight = this.pivotData.length * this.gridViewRowHeight;
  }

  onRowAction(event) {
    if (event.action === 'expand') {
      this.pivotGridManager.expandSingleRow(event.rowData);
    } else {
      this.pivotGridManager.collapseSingleRow(event.rowData);
    }
    setTimeout(() => {
      this.scrollableHeight =
        this.verticalScroll.nativeElement.scrollHeight - this.verticalScroll.nativeElement.clientHeight;
    }, 100);
  }

  onHoverRow(event) {
    this.hoveredRowId = event;
  }

  performResizeColumn(columnChanges: any) {
    this.pivotGridManager.handleResizeColumn(columnChanges);
  }

  toggleRows(rowChanges: any) {
    this.pivotGridManager.toggleAllRows(rowChanges);
  }

  closeContextMenu() {
    this.contextMenu.closeContextMenu();
  }

  handleContextMenu(eventData) {
    const groupPropIndex = this.leftProperties.findIndex((prop) => prop.slug === eventData.row.propertySlug);
    if (groupPropIndex < this.leftProperties.length - 1) {
      const groupProp = this.leftProperties[groupPropIndex];
      this.contextMenu.onContextMenu(eventData.event, eventData.row, groupProp.propertyDefinition.label);
    }
  }

  handleRowAction(eventData) {
    if (eventData.event === 'expand-rows') {
      this.pivotGridManager.expandRowsAtLevel(eventData.propLevel);
    } else {
      this.pivotGridManager.collapseRowsAtLevel(eventData.propLevel);
    }
  }
}

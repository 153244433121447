import { ChangeDetectorRef, Component, ElementRef, HostListener, ViewChild, ViewContainerRef } from '@angular/core';
import { ObjectUtil } from '@contrail/util';
import { TypePropertyFormFieldBaseComponent } from './type-property-form-field-base.component';
import { PAGINATED_ENTITY_TYPES } from '@common/entities/entities.interfaces';

@Component({
  selector: 'app-type-property-form-field-object-reference',
  template: `
    <div class="pb-5">
      <div [ngClass]="!editable ? 'disabled' : ''">
        {{ propertyFormConfiguration.typeProperty.label }}<span *ngIf="required">&nbsp;*</span>
      </div>
      <div class="mt-[5val > px]">
        <app-object-reference-chip
          [object]="value"
          [typeProperty]="propertyFormConfiguration.typeProperty"
          maxDisplayLength="16"
          *ngIf="value && value.hasOwnProperty('id')"
          (clicked)="showDetails()"
          (clearValue)="clearValue()"
          [edit]="editable"
          [allowDisplayTextShortening]="false"
        >
        </app-object-reference-chip>
        <div
          *ngIf="(!value || !value.hasOwnProperty('id')) && editable"
          class="add-button"
          (click)="openChooser($event)"
        >
          <mat-icon>add</mat-icon>
        </div>
        <div *ngIf="errorMessage" class="w-full text-[75%] mt-1 border-t-2 border-red-500">
          <mat-error>{{ errorMessage }}</mat-error>
        </div>
      </div>
    </div>
    <div #chooserPanel class="panel" [ngStyle]="{ visibility: showChooser ? 'visible' : 'hidden' }">
      <ng-template #objectReferenceChooser></ng-template>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
      .add-button {
        height: 16px;
        width: 16px;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        mat-icon {
          font-size: 15px;
          height: 15px;
          width: 15px;
        }
      }
      .add-button:hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
      .panel {
        position: fixed;
        min-width: 300px;
        height: 400px;
        z-index: 300;
        margin-top: 24px;
        padding: 15px;
        background: rgb(255, 255, 255) !important;
        border: 0.5px solid rgba(118, 118, 118, 0.28) !important;
        border-radius: 8px !important;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 10px 22px !important;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .disabled {
        color: rgba(0, 0, 0, 0.38);
      }
    `,
  ],
})
export class TypePropertyFormFieldObjectReferenceComponent extends TypePropertyFormFieldBaseComponent {
  @ViewChild('chooserPanel') chooserPanel: ElementRef;
  @ViewChild('objectReferenceChooser', { read: ViewContainerRef })
  objectReferenceChooserComponent: ViewContainerRef;
  showChooser = false;
  editable = true;
  required = false;

  @HostListener('click', ['$event']) onClick(event) {
    let hideChooser = true;
    const objHierarchy = event.path || (event.composedPath && event.composedPath());
    objHierarchy.forEach((el) => {
      if ('app-object-reference-chooser' === el.tagName?.toLowerCase()) {
        // only allow close if the click happens outside of the chooser.
        hideChooser = false;
      }
    });
    if (this.showChooser && hideChooser) {
      this.closeChooser();
    }
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
  ) {
    super();
  }

  async initFormControl() {
    this.editable = !(await this.isDisabled());
    this.required = this.propertyFormConfiguration.typeProperty.required;
  }

  async openChooser(event) {
    event.stopPropagation();
    this.showChooser = true;
    const isInfiniteScrollEnabled = PAGINATED_ENTITY_TYPES.includes(
      this.propertyFormConfiguration.typeProperty.referencedTypeRootSlug,
    );

    setTimeout(async () => {
      const { ObjectReferenceChooserComponent } = await import(
        '../../../../object-reference/object-reference-chooser/object-reference-chooser.component'
      );
      this.objectReferenceChooserComponent.clear();
      const componentRef = this.objectReferenceChooserComponent.createComponent(ObjectReferenceChooserComponent);
      componentRef.instance.typeProperty = this.propertyFormConfiguration.typeProperty;
      componentRef.instance.enableInfiniteScroll = isInfiniteScrollEnabled;
      componentRef.instance.entitySelected.subscribe((entity) => this.handleEntitySelected(entity));
      this.cdr.markForCheck();
      this.setPosition();
    }, 1);
  }

  closeChooser() {
    this.objectReferenceChooserComponent.clear();
    this.showChooser = false;
  }

  setPosition() {
    const panelHeight = 400;
    const pageY = this.elementRef.nativeElement.getBoundingClientRect().y;
    const chooserContainerHeight = 450;
    let panelTopPosition = this.elementRef.nativeElement.getBoundingClientRect().top + 30;
    const maxDim =
      window.innerHeight * 0.4 > chooserContainerHeight ? chooserContainerHeight : window.innerHeight * 0.4;
    const distanceFromBottom = (100 * (window.innerHeight - maxDim - pageY)) / window.innerHeight;
    if (distanceFromBottom < 12) {
      panelTopPosition = panelTopPosition - panelHeight - 20;
    }
    this.chooserPanel.nativeElement.style.top = `${panelTopPosition}px`;
  }

  handleEntitySelected(entity) {
    this.closeChooser();
    this.value = ObjectUtil.cloneDeep(entity);
    this.value[this.propertyFormConfiguration.typeProperty.slug + 'Id'] = this.value.id;
    this.handleChange();
  }

  showDetails() {}

  clearValue() {
    this.value = {};
    this.value[this.propertyFormConfiguration.typeProperty.slug + 'Id'] = null;
    this.handleChange();
  }

  isInvalid(): boolean {
    if (
      this.propertyFormConfiguration.typeProperty.required &&
      (!this.value || !this.value[this.propertyFormConfiguration.typeProperty.slug + 'Id'])
    ) {
      return true;
    }
    return false;
  }
}

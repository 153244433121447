<div class="object-details" (click)="handleClick($event)">
  <div class="selector-checkbox" *ngIf="allowAddMultiple">
    <!-- <mat-checkbox
      color="primary"
      [attr.data-test]="'chooser-checkbox-' + entity.id"
      (change)="toggleCheckbox($event)"
      [checked]="selectedItemIndex > 0"
    ></mat-checkbox> -->
  </div>
  <div class="image-placeholder" *ngIf="!entity.thumbnail && !entity.hex">
    <mat-icon>panorama</mat-icon>
  </div>
  <div *ngIf="entity.thumbnail" class="image-holder">
    <img [attr.src]="entity.thumbnail | secureImage | async" />
  </div>
  <div *ngIf="!entity.thumbnail && entity.hex" class="image-holder">
    <div class="color-card" [style.backgroundColor]="'#' + entity.hex"></div>
  </div>
  <div class="details">
    <div class="name">{{ entity?.name }}</div>
    <div class="meta" *ngIf="isEntityItemOption">
      <mat-icon class="option-icon" svgIcon="hierarchy"></mat-icon>
      {{ entity?.optionName }}
    </div>
  </div>
</div>
<div class="actions">
  <!-- <button mat-button [attr.data-test]="'add-single-item-button-' + dataObj.id" *ngIf="allowAdd && selectedItemIndex === 0" (click)="addItemData(dataObj)"><mat-icon>{{ getAddIcon() }}</mat-icon></button>
  <div [attr.data-test]="'selected-item-index' + dataObj.id" class="selected-item" *ngIf="selectedItemIndex > 0">{{selectedItemIndex}}</div> -->
</div>

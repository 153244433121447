import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import {
  AggregateColumnEntity,
  GroupingPropertyValue,
  PivotAggregateHelper,
} from '@components/pivot-grid/pivot-aggregate-helper';
import {
  DEFAULT_COLUMN_WIDTH_PX,
  DEFAULT_ROW_HEIGHT_PX,
  PivotHeaderColumnResizeHelper,
} from '../pivot-header-column-resize-helper';

@Component({
  selector: 'app-pivot-grouping-column-headers',
  templateUrl: './pivot-grouping-column-headers.component.html',
  styleUrls: ['./pivot-grouping-column-headers.component.scss'],
})
export class PivotGroupingColumnHeadersComponent implements OnInit, OnChanges {
  @Input() columnGroupingValues: AggregateColumnEntity;
  @Input() level: number = 0;
  @Input() numberOfLevelsToOffset: number = 0;
  @Input() showGoals: boolean = false;
  @Output() resizeColumn: EventEmitter<any> = new EventEmitter();

  public heightPx: number = DEFAULT_ROW_HEIGHT_PX;
  public widthPx: number = DEFAULT_COLUMN_WIDTH_PX;

  constructor(private columnResizeHelper: PivotHeaderColumnResizeHelper) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.heightPx = this.columnResizeHelper.getHeaderRowHeightInPixels(this.columnGroupingValues, {
      includeGoalsRow: this.showGoals,
      numberOfLevelsToOffset: this.numberOfLevelsToOffset,
    });

    this.widthPx = this.getHeaderColumnWidthInPixels();
  }

  get widthPercentage() {
    if (this.level === 0) {
      return;
    }

    return (1 / this.columnGroupingValues.values.length) * 100;
  }

  private getHeaderColumnWidthInPixels() {
    if (this.level !== 0) {
      return;
    }

    if (!this.columnGroupingValues?.child) {
      return DEFAULT_COLUMN_WIDTH_PX;
    }

    const groupingValuesOfOneColumn = this.columnGroupingValues.child;
    return this.columnResizeHelper.getHeaderColumnWidthInPixels(groupingValuesOfOneColumn);
  }

  public getLabelOfValue(value: GroupingPropertyValue): string {
    if (value.label === PivotAggregateHelper.EMPTY_VALUE) {
      // this.addClass('italic');
      // this.addClass('text-gray-400');
      return 'Empty';
    }

    return value.label;
  }
}

<div
  *ngFor="let value of columnGroupingValues.values; index as i"
  [style.width.px]="widthPx"
  [style.width.%]="widthPercentage"
  [style.height.px]="heightPx"
  [ngClass]="{
    'root-header-cell': level === 0,
    'sub-header-cell': level > 0,
    'sub-header-cell-right': level > 0 && i > 0
  }"
>
  <app-pivot-grouping-header-cell
    class="w-full"
    [value]="value"
    [childColumns]="columnGroupingValues.child"
    [showGoals]="showGoals"
    [level]="level"
    [numberOfLevelsToOffset]="numberOfLevelsToOffset"
  ></app-pivot-grouping-header-cell>
</div>

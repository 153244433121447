<div class="grid-totals">
  <ng-container *ngFor="let columnKey of columnGroupingKeys">
    <app-column-aggregate
      [style.width.px]="property.width || 150"
      class="header-cell justify-center"
      *ngFor="let property of properties; last as isLast"
      [aggregateRow]="aggregateRow"
      [property]="property"
      [columnKey]="columnKey"
      [showGoals]="showGoals"
    >
    </app-column-aggregate>
  </ng-container>

  <ng-container *ngIf="!columnGroupingKeys.length">
    <app-column-aggregate
      [style.width.px]="property.width || 150"
      class="header-cell justify-center"
      *ngFor="let property of properties; last as isLast"
      [aggregateRow]="aggregateRow"
      [property]="property"
      [showGoals]="showGoals"
    >
    </app-column-aggregate>
  </ng-container>

  <app-column-aggregate
    [style.width.px]="property.width || 150"
    class="header-cell justify-center"
    *ngFor="let property of properties; last as isLast"
    [ngClass]="{ last: isLast }"
    [aggregateRow]="aggregateRow"
    [property]="property"
    [showGoals]="showGoals"
  >
  </app-column-aggregate>
</div>

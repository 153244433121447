<div
  [style.height.px]="side === 'right' ? rowContainerHeight + VALUE_ROWS_OFFSET : rowContainerHeight"
  [style.width.px]="side === 'right' ? sideWidth : undefined"
  class="grid-rows-wrapper"
  [ngClass]="{ left: side === 'left', right: side === 'right' }"
>
  <div class="grid-rows">
    <app-pivot-data-row
      [dataRow]="dataRow.data"
      [properties]="properties"
      [rowGroupingProperties]="rowGroupingProperties"
      [columnGroupingKeys]="columnGroupingKeys"
      [side]="side"
      [goals]="goals"
      [showGoals]="showGoals"
      [rowNumber]="i"
      (rowAction)="onRowAction($event)"
      (onContextMenu)="handleContextMenu($event)"
      (hoverRow)="onHoverRow($event)"
      [hoveredRowId]="hoveredRowId"
      *ngFor="let dataRow of visibleDataRows; trackBy: rowTrackByFn; let i = index"
    >
    </app-pivot-data-row>
    <div class="last-grid-row" [style.top.px]="visibleDataRows.length * ROW_HEIGHT"></div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { ViewDefinition, ViewPropertyConfiguration } from '@contrail/client-views';
import { AggregateColumnEntity } from '../pivot-aggregate-helper';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { ChangeObject } from '@common/entities/entities.interfaces';

export const DEFAULT_COLUMN_WIDTH_PX = 150;
export const DEFAULT_ROW_HEIGHT_PX = 28;

@Injectable({
  providedIn: 'root',
})
export class PivotHeaderColumnResizeHelper {
  private headerYCoord;
  private draggedDistance = 0;
  private currentHeaderProperty: ViewPropertyConfiguration;
  private currentHeaderElement: HTMLElement;
  private dragStartEventLocation: any;

  private readonly MIN_COLUMN_WIDTH_PX = 45;

  constructor(private store: Store<RootStoreState.State>) {}

  // RESIZE COLUMN LOGIC
  handleColumnResizeDragStart(property: ViewPropertyConfiguration) {
    this.currentHeaderProperty = property;
    this.currentHeaderElement = document.getElementById('column_header_' + this.currentHeaderProperty.slug);
    this.dragStartEventLocation = null;
  }

  handleColumnResizeDragMove(event) {
    if (!this.headerYCoord) {
      const gridBodyBox = document.getElementById('grid-body').getBoundingClientRect();
      this.headerYCoord = gridBodyBox.y;
    }
    // HANDLE START OF DRAG
    if (!this.dragStartEventLocation) {
      this.dragStartEventLocation = event.pointerPosition;
    }

    const dragPreview = document.getElementById('column_resize_' + this.currentHeaderProperty.slug);
    const xPos = event.pointerPosition.x;
    this.draggedDistance = xPos - this.dragStartEventLocation.x;

    // HANDLE START OF DRAG
    if (!this.dragStartEventLocation) {
      this.dragStartEventLocation = event.pointerPosition;
    }

    dragPreview.style.transform = `translate3d(${xPos}px, ${this.headerYCoord}px, 0)`;
  }

  buildUpdatedViewForColumnResizeDragDrop(viewDefinition: ViewDefinition): ChangeObject<ViewDefinition> {
    const viewProperties = viewDefinition?.properties?.map((property) => ({ ...property }));
    const column = viewProperties?.find((prop) => prop.slug === this.currentHeaderProperty.slug);
    if (!column) {
      return;
    }
    column.width = column.width || DEFAULT_COLUMN_WIDTH_PX;
    column.width += this.draggedDistance;
    if (column.width < 45) {
      column.width = 45;
    }

    return { id: viewDefinition.id, changes: { properties: viewProperties } };
  }

  public getHeaderRowHeightInPixels(
    column: AggregateColumnEntity,
    options: { includeGoalsRow: boolean; numberOfLevelsToOffset: number },
  ): number {
    const numberOfHeaderRows = this.getCountOfHeaderRows(column);
    const goalsOffset = options?.includeGoalsRow ? 1 : 0;
    const levelOffset = options?.numberOfLevelsToOffset || 0;

    return (numberOfHeaderRows + goalsOffset + levelOffset) * DEFAULT_ROW_HEIGHT_PX;
  }

  public getCountOfHeaderRows(column: AggregateColumnEntity) {
    let count = 1;
    let nextHeaderColumn = column;
    while (nextHeaderColumn) {
      const nextChildRow = nextHeaderColumn.child;
      if (!nextChildRow || !nextChildRow.values.length) {
        break;
      }

      count++;
      nextHeaderColumn = nextChildRow;
    }

    return count;
  }

  public getHeaderColumnWidthInPixels(column: AggregateColumnEntity) {
    const numberOfBottomLevelColumns = this.getCountOfAllBottomLevelValuesInColumn(column);
    return numberOfBottomLevelColumns * DEFAULT_COLUMN_WIDTH_PX;
  }

  private getCountOfAllBottomLevelValuesInColumn(column: AggregateColumnEntity): number {
    if (!column.child) {
      return column.values.length || 1;
    }

    const currentLevelCount = column.values.length;
    const childLevelCount = this.getCountOfAllBottomLevelValuesInColumn(column.child);

    return currentLevelCount * childLevelCount;
  }
}

import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { ViewDefinition, ViewPropertyConfiguration } from '@contrail/client-views';
import { AggregateColumnEntity, PivotAggregateHelper } from '../pivot-aggregate-helper';
import { DEFAULT_ROW_HEIGHT_PX, PivotHeaderColumnResizeHelper } from './pivot-header-column-resize-helper';

@Component({
  selector: 'app-pivot-header-row',
  templateUrl: './pivot-header-row.component.html',
  styleUrls: ['./pivot-header-row.component.scss'],
})
export class PivotHeaderRowComponent implements OnDestroy, OnChanges {
  @Input() viewDefinition: ViewDefinition;
  @Input() rowGroupingProperties?: Array<ViewPropertyConfiguration>;
  @Input() columnGroupingValues?: AggregateColumnEntity;
  @Input() side: string;
  @Input() showGoals: boolean = false;
  @Input() headerHeightPx: number = DEFAULT_ROW_HEIGHT_PX;
  @Output() resizeColumn: EventEmitter<any> = new EventEmitter();
  @Output() toggleRows: EventEmitter<any> = new EventEmitter();

  public totalWidth = 0;
  public numberOfLevelsToOffsetForGrandTotals = 0;
  public numberOfLevelsToOffsetForColumnGroupings = 0;
  public groupingHeader = '';
  public groupingProperty: ViewPropertyConfiguration;
  public grandTotalColumns: AggregateColumnEntity;

  constructor(private columnResizeHelper: PivotHeaderColumnResizeHelper) {}

  ngOnDestroy(): void {}

  ngOnChanges(): void {
    const isRowGroupingHeader = this.side === 'left' && this.rowGroupingProperties?.length > 0;
    if (isRowGroupingHeader) {
      this.groupingProperty = this.rowGroupingProperties[0];
      this.totalWidth = this.rowGroupingProperties[0].width || this.rowGroupingProperties.length * 100;
      this.groupingHeader = '';
      this.rowGroupingProperties.forEach((prop) => {
        this.groupingHeader += (this.groupingHeader !== '' ? ' / ' : '') + prop.propertyDefinition.label;
      });
    }

    const isColumnGroupingHeader = this.side === 'right';
    if (isColumnGroupingHeader) {
      this.grandTotalColumns = PivotAggregateHelper.buildAggregateColumnsForGrandTotals(this.viewDefinition);
      const numberOfGrandTotalHeaders = this.columnResizeHelper.getCountOfHeaderRows(this.grandTotalColumns);
      const numberOfColumnGroupingHeaders = this.columnResizeHelper.getCountOfHeaderRows(this.columnGroupingValues);

      const grandTotalOffset = numberOfColumnGroupingHeaders - numberOfGrandTotalHeaders;
      const columnGroupingOffset = numberOfGrandTotalHeaders - numberOfColumnGroupingHeaders;

      this.numberOfLevelsToOffsetForGrandTotals = grandTotalOffset > 0 ? grandTotalOffset : 0;
      this.numberOfLevelsToOffsetForColumnGroupings = columnGroupingOffset > 0 ? columnGroupingOffset : 0;
    }
  }

  performResizeColumn(columnChanges: any) {
    this.resizeColumn.emit(columnChanges);
  }

  performToggleRows(columnChanges: any) {
    this.toggleRows.emit(columnChanges);
  }
}

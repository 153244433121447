import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-chooser-entity-card',
  templateUrl: './chooser-entity-card.component.html',
  styleUrls: ['./chooser-entity-card.component.scss'],
})
export class ChooserEntityCardComponent implements OnInit {
  @Input() entity: any;
  @Input() entityType: string;
  @Input() allowAddMultiple = false;
  @Output() entityClicked = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  handleClick(event) {
    event.stopPropagation();
    this.entityClicked.emit(this.entity);
  }

  get isEntityItemOption() {
    return Boolean(this.entityType === 'item' && this.entity?.optionName && this.entity?.roles?.includes('option'));
  }
}

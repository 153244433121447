<div
  *ngIf="!showGoals"
  [attr.data-test]="
    'cell-aggregate-' + property.slug + '-' + property.aggregateFunction + '-' + (columnKey ? columnKey : 'grand-total')
  "
  class="value justify-center"
>
  {{ display }}
</div>

<div
  *ngIf="showGoals"
  [attr.data-test]="'cell-aggregate-' + property.slug"
  class="justify-center flex w-full relative h-full"
>
  <div style="width: 50%" class="value flex justify-center text-ellipsis overflow-hidden whitespace-nowrap">
    {{ display }}
  </div>
  <div style="width: 50%" class="value flex justify-center text-ellipsis overflow-hidden whitespace-nowrap split-cell">
    {{ goalDisplay }}
  </div>
</div>

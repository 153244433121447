<div class="grid-header">
  <ng-container *ngIf="side === 'right'">
    <app-pivot-grouping-column-headers
      [columnGroupingValues]="columnGroupingValues"
      [level]="0"
      [numberOfLevelsToOffset]="numberOfLevelsToOffsetForColumnGroupings"
      [showGoals]="showGoals"
      (resizeColumn)="performResizeColumn($event)"
    >
    </app-pivot-grouping-column-headers>

    <app-pivot-grouping-column-headers
      *ngIf="grandTotalColumns"
      class="grand-total-headers"
      [columnGroupingValues]="grandTotalColumns"
      [level]="0"
      [numberOfLevelsToOffset]="numberOfLevelsToOffsetForGrandTotals"
      [showGoals]="showGoals"
      (resizeColumn)="performResizeColumn($event)"
    >
    </app-pivot-grouping-column-headers>
  </ng-container>

  <app-pivot-column-header
    *ngIf="side === 'left'"
    [style.width.px]="groupingProperty.width || totalWidth"
    [style.height.px]="headerHeightPx"
    class="header-cell"
    [fixedLabel]="groupingHeader"
    [property]="groupingProperty"
    [showGoals]="showGoals"
    [viewDefinition]="viewDefinition"
    (resizeColumn)="performResizeColumn($event)"
    (toggleRows)="performToggleRows($event)"
  >
  </app-pivot-column-header>
</div>
